var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "form-main", attrs: { id: "resumo" } },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Dados de Identificação")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Protocolo\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.cliente.protocolo) + "\n    ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Código do Correspondente\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.cliente.codCorrespondente) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Declaração de Propósitos")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: { "align-self": _vm.style.alignSelf }
            },
            [
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c("div", { staticClass: "d-flex flex-row align-center" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value:
                          _vm.cliente.declaracaoPropositos
                            .movimentacaoContaDepositoPoupanca,
                        expression:
                          "cliente.declaracaoPropositos.movimentacaoContaDepositoPoupanca"
                      }
                    ],
                    attrs: {
                      disabled: "",
                      id: "movimentaContaDepositoPoupanca",
                      name: "movimentaContaDepositoPoupanca",
                      type: "checkbox"
                    },
                    domProps: {
                      checked: Array.isArray(
                        _vm.cliente.declaracaoPropositos
                          .movimentacaoContaDepositoPoupanca
                      )
                        ? _vm._i(
                            _vm.cliente.declaracaoPropositos
                              .movimentacaoContaDepositoPoupanca,
                            null
                          ) > -1
                        : _vm.cliente.declaracaoPropositos
                            .movimentacaoContaDepositoPoupanca
                    },
                    on: {
                      change: function($event) {
                        var $$a =
                            _vm.cliente.declaracaoPropositos
                              .movimentacaoContaDepositoPoupanca,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.cliente.declaracaoPropositos,
                                "movimentacaoContaDepositoPoupanca",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.cliente.declaracaoPropositos,
                                "movimentacaoContaDepositoPoupanca",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.cliente.declaracaoPropositos,
                            "movimentacaoContaDepositoPoupanca",
                            $$c
                          )
                        }
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "form-checkbox-label",
                      attrs: { for: "movimentaContaDepositoPoupanca" }
                    },
                    [
                      _vm._v(
                        "Movimentação de Conta de\n            Depósito/Poupança"
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "d-flex flex-row align-center" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value:
                          _vm.cliente.declaracaoPropositos
                            .emprestimosFinanciamentos,
                        expression:
                          "cliente.declaracaoPropositos.emprestimosFinanciamentos"
                      }
                    ],
                    attrs: {
                      disabled: "",
                      id: "emprestimosFinanciamentos",
                      name: "emprestimosFinanciamentos",
                      type: "checkbox"
                    },
                    domProps: {
                      checked: Array.isArray(
                        _vm.cliente.declaracaoPropositos
                          .emprestimosFinanciamentos
                      )
                        ? _vm._i(
                            _vm.cliente.declaracaoPropositos
                              .emprestimosFinanciamentos,
                            null
                          ) > -1
                        : _vm.cliente.declaracaoPropositos
                            .emprestimosFinanciamentos
                    },
                    on: {
                      change: function($event) {
                        var $$a =
                            _vm.cliente.declaracaoPropositos
                              .emprestimosFinanciamentos,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.cliente.declaracaoPropositos,
                                "emprestimosFinanciamentos",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.cliente.declaracaoPropositos,
                                "emprestimosFinanciamentos",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.cliente.declaracaoPropositos,
                            "emprestimosFinanciamentos",
                            $$c
                          )
                        }
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "form-checkbox-label",
                      attrs: { for: "emprestimosFinanciamentos" }
                    },
                    [_vm._v("Empréstimos/Financiamentos")]
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-row align-center justify-center",
                    attrs: { id: "habitacional" }
                  },
                  [
                    _c("label", { staticClass: "form-checkbox-label" }, [
                      _vm._v("Financiamento Habitacional")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.cliente.declaracaoPropositos
                              .financiamentoHabitacional,
                          expression:
                            "cliente.declaracaoPropositos.financiamentoHabitacional"
                        }
                      ],
                      attrs: {
                        disabled: "",
                        id: "habitacionalSIM",
                        name: "habitacional",
                        type: "radio"
                      },
                      domProps: {
                        value: true,
                        checked: _vm._q(
                          _vm.cliente.declaracaoPropositos
                            .financiamentoHabitacional,
                          true
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.cliente.declaracaoPropositos,
                            "financiamentoHabitacional",
                            true
                          )
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "form-checkbox-label",
                        attrs: { for: "habitacionalSIM" }
                      },
                      [_vm._v("SIM")]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value:
                            _vm.cliente.declaracaoPropositos
                              .financiamentoHabitacional,
                          expression:
                            "cliente.declaracaoPropositos.financiamentoHabitacional"
                        }
                      ],
                      attrs: {
                        disabled: "",
                        id: "habitacionalNAO",
                        name: "habitacional",
                        type: "radio"
                      },
                      domProps: {
                        value: false,
                        checked: _vm._q(
                          _vm.cliente.declaracaoPropositos
                            .financiamentoHabitacional,
                          false
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.cliente.declaracaoPropositos,
                            "financiamentoHabitacional",
                            false
                          )
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "form-checkbox-label",
                        attrs: { for: "habitacionalNAO" }
                      },
                      [_vm._v("NÃO")]
                    )
                  ]
                ),
                _c("div", { staticClass: "d-flex flex-row align-center" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.cliente.declaracaoPropositos.investimentos,
                        expression: "cliente.declaracaoPropositos.investimentos"
                      }
                    ],
                    attrs: {
                      disabled: "",
                      id: "investimentos",
                      name: "investimentos",
                      type: "checkbox"
                    },
                    domProps: {
                      checked: Array.isArray(
                        _vm.cliente.declaracaoPropositos.investimentos
                      )
                        ? _vm._i(
                            _vm.cliente.declaracaoPropositos.investimentos,
                            null
                          ) > -1
                        : _vm.cliente.declaracaoPropositos.investimentos
                    },
                    on: {
                      change: function($event) {
                        var $$a =
                            _vm.cliente.declaracaoPropositos.investimentos,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.cliente.declaracaoPropositos,
                                "investimentos",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.cliente.declaracaoPropositos,
                                "investimentos",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.cliente.declaracaoPropositos,
                            "investimentos",
                            $$c
                          )
                        }
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "form-checkbox-label",
                      attrs: { for: "investimentos" }
                    },
                    [_vm._v("Investimentos")]
                  )
                ]),
                _c("div", { staticClass: "d-flex flex-row align-center" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.cliente.declaracaoPropositos.cartaoCredito,
                        expression: "cliente.declaracaoPropositos.cartaoCredito"
                      }
                    ],
                    attrs: {
                      disabled: "",
                      id: "cartaoCredito",
                      name: "cartaoCredito",
                      type: "checkbox"
                    },
                    domProps: {
                      checked: Array.isArray(
                        _vm.cliente.declaracaoPropositos.cartaoCredito
                      )
                        ? _vm._i(
                            _vm.cliente.declaracaoPropositos.cartaoCredito,
                            null
                          ) > -1
                        : _vm.cliente.declaracaoPropositos.cartaoCredito
                    },
                    on: {
                      change: function($event) {
                        var $$a =
                            _vm.cliente.declaracaoPropositos.cartaoCredito,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.cliente.declaracaoPropositos,
                                "cartaoCredito",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.cliente.declaracaoPropositos,
                                "cartaoCredito",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.cliente.declaracaoPropositos,
                            "cartaoCredito",
                            $$c
                          )
                        }
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "form-checkbox-label",
                      attrs: { for: "cartaoCredito" }
                    },
                    [_vm._v("Cartão de Crédito")]
                  )
                ]),
                _c("div", { staticClass: "d-flex flex-row align-center" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value:
                          _vm.cliente.declaracaoPropositos
                            .seguroPrevidenciaCapitalizacaoConsorcios,
                        expression:
                          "cliente.declaracaoPropositos.seguroPrevidenciaCapitalizacaoConsorcios"
                      }
                    ],
                    attrs: {
                      disabled: "",
                      id: "seguroPrevidenciaCapitalizacaoConsorcios",
                      name: "seguroPrevidenciaCapitalizacaoConsorcios",
                      type: "checkbox"
                    },
                    domProps: {
                      checked: Array.isArray(
                        _vm.cliente.declaracaoPropositos
                          .seguroPrevidenciaCapitalizacaoConsorcios
                      )
                        ? _vm._i(
                            _vm.cliente.declaracaoPropositos
                              .seguroPrevidenciaCapitalizacaoConsorcios,
                            null
                          ) > -1
                        : _vm.cliente.declaracaoPropositos
                            .seguroPrevidenciaCapitalizacaoConsorcios
                    },
                    on: {
                      change: function($event) {
                        var $$a =
                            _vm.cliente.declaracaoPropositos
                              .seguroPrevidenciaCapitalizacaoConsorcios,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.cliente.declaracaoPropositos,
                                "seguroPrevidenciaCapitalizacaoConsorcios",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.cliente.declaracaoPropositos,
                                "seguroPrevidenciaCapitalizacaoConsorcios",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.cliente.declaracaoPropositos,
                            "seguroPrevidenciaCapitalizacaoConsorcios",
                            $$c
                          )
                        }
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "form-checkbox-label",
                      attrs: { for: "seguroPrevidenciaCapitalizacaoConsorcios" }
                    },
                    [
                      _vm._v(
                        "Seguros/Previdência\n            Privada/Capitalização/Consórcios"
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "d-flex flex-row align-center" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value:
                          _vm.cliente.declaracaoPropositos
                            .operacoesInternacionaisCambio,
                        expression:
                          "cliente.declaracaoPropositos.operacoesInternacionaisCambio"
                      }
                    ],
                    attrs: {
                      disabled: "",
                      id: "operacoesInternacionaisCambio",
                      name: "operacoesInternacionaisCambio",
                      type: "checkbox"
                    },
                    domProps: {
                      checked: Array.isArray(
                        _vm.cliente.declaracaoPropositos
                          .operacoesInternacionaisCambio
                      )
                        ? _vm._i(
                            _vm.cliente.declaracaoPropositos
                              .operacoesInternacionaisCambio,
                            null
                          ) > -1
                        : _vm.cliente.declaracaoPropositos
                            .operacoesInternacionaisCambio
                    },
                    on: {
                      change: function($event) {
                        var $$a =
                            _vm.cliente.declaracaoPropositos
                              .operacoesInternacionaisCambio,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.cliente.declaracaoPropositos,
                                "operacoesInternacionaisCambio",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.cliente.declaracaoPropositos,
                                "operacoesInternacionaisCambio",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(
                            _vm.cliente.declaracaoPropositos,
                            "operacoesInternacionaisCambio",
                            $$c
                          )
                        }
                      }
                    }
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "form-checkbox-label",
                      attrs: { for: "operacoesInternacionaisCambio" }
                    },
                    [_vm._v("Operações\n            Internacionais/Câmbio")]
                  )
                ])
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Dados Cadastrais")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      CPF do Cliente\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.cliente.cpf) + "\n    ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Nome Completo do Cliente\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.cliente.nomeCompleto) + "\n    ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Nome Reduzido do Cliente\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.cliente.nomeReduzido) + "\n    ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Data de Nascimento\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [
                ..._vm.style.colRightClasses,
                "form-col-left",
                "text-uppercase"
              ],
              attrs: { "align-self": _vm.style.alignSelf, cols: "3" }
            },
            [_vm._v("\n      " + _vm._s(_vm.cliente.dataNascimento) + "\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "2" }
            },
            [_vm._v("\n      Sexo\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: { "align-self": _vm.style.alignSelf, cols: "3" }
            },
            [_vm._v("\n      " + _vm._s(_vm.cliente.sexo) + "\n    ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Nacionalidade\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.cliente.nacionalidade) + "\n    ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Naturalidade\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.cliente.naturalidade.municipio) +
                  " / " +
                  _vm._s(_vm.cliente.naturalidade.uf) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Nome do Pai\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.cliente.nomePai) + "\n    ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Nome do Mãe\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.cliente.nomeMae) + "\n    ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Grau de Instrução\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.cliente.grauInstrucao) + "\n    ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      PIS/NIS\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.cliente.pisNis) + "\n    ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Tipo de Documento de Identidade\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.cliente.tipoDocumento.value) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _vm.isDocumentoIdentificacaoVisible
        ? _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("\n      Subtipo do Documento\n    ")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.cliente.tipoDocumento.documentoIdentificacao.subTipo
                      ) +
                      "\n    "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.cliente.tipoDocumento.value
        ? _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [
                  _vm.isPassaporteVisible
                    ? _c("span", [_vm._v("Número do Passaporte")])
                    : _vm._e(),
                  _vm.isCnhVisible
                    ? _c("span", [
                        _vm._v("Número da Carteira Nacional de Habilitação")
                      ])
                    : _c("span", [_vm._v("Número da Identidade")])
                ]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.cliente.tipoDocumento.numero) +
                      "\n    "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.cliente.tipoDocumento.value
        ? _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("\n      Órgão Emissor\n    ")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.cliente.tipoDocumento.orgaoEmissor) +
                      "\n    "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.cliente.tipoDocumento.value
        ? _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("\n      UF\n    ")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.cliente.tipoDocumento.uf) + "\n    "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isPassaporteVisible
        ? _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("\n      País de origem\n    ")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.cliente.tipoDocumento.passaporte.paisOrigem) +
                      "\n    "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isCnhVisible
        ? _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("\n      Data 1ª Habilitação\n    ")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colRight
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.cliente.tipoDocumento.dataPrimeiraHabilitacao
                      ) +
                      "\n    "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.cliente.tipoDocumento.value
        ? _c(
            "v-row",
            { staticClass: "form-row", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  class: _vm.style.colLeftClasses,
                  attrs: {
                    "align-self": _vm.style.alignSelf,
                    cols: _vm.style.colLeft
                  }
                },
                [_vm._v("\n      Data de Emissão\n    ")]
              ),
              _c(
                "v-col",
                {
                  class: [..._vm.style.colRightClasses, "form-col-left"],
                  attrs: { "align-self": _vm.style.alignSelf, cols: "2" }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.cliente.tipoDocumento.dataEmissao) +
                      "\n    "
                  )
                ]
              ),
              _c(
                "v-col",
                {
                  class: [
                    ..._vm.style.colLeftClasses,
                    "text-no-wrap",
                    "form-fim-val-label"
                  ],
                  attrs: { "align-self": _vm.style.alignSelf, cols: "3" }
                },
                [_vm._v("\n      Data Fim Validade\n    ")]
              ),
              _c(
                "v-col",
                {
                  class: _vm.style.colRightClasses,
                  attrs: { "align-self": _vm.style.alignSelf, cols: "3" }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.cliente.tipoDocumento.dataFimValidade) +
                      "\n    "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Estado Civil\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.cliente.estadoCivil) + "\n    ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Tipo de Ocupação\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.cliente.ocupacao) + "\n    ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Endereço")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      CEP\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.cliente.endereco.cep) + "\n    ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Logradouro\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.cliente.endereco.tipoLogradouro) +
                  " " +
                  _vm._s(_vm.cliente.endereco.logradouro) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Complemento\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [
                ..._vm.style.colRightClasses,
                "form-col-left",
                "text-uppercase"
              ],
              attrs: { "align-self": _vm.style.alignSelf, cols: "5" }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.cliente.endereco.complemento) + "\n    "
              )
            ]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "2" }
            },
            [_vm._v("\n      Número\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: { "align-self": _vm.style.alignSelf, cols: "1" }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.cliente.endereco.numero) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Município\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [
                ..._vm.style.colRightClasses,
                "form-col-left",
                "text-uppercase"
              ],
              attrs: { "align-self": _vm.style.alignSelf, cols: "5" }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.cliente.endereco.cidade) + "\n    "
              )
            ]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "2" }
            },
            [_vm._v("\n      Número\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: { "align-self": _vm.style.alignSelf, cols: "1" }
            },
            [_vm._v("\n      " + _vm._s(_vm.cliente.endereco.uf) + "\n    ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Bairro\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.cliente.endereco.bairro) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Tipo de Imóvel\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.cliente.endereco.tipoImovel) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Ocupação do Imóvel\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.cliente.endereco.ocupacaoImovel) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Comprovante de Residência\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.cliente.endereco.comprovanteResidenciaMes) +
                  "/" +
                  _vm._s(_vm.cliente.endereco.comprovanteResidenciaAno) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Telefones")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "6" }
            },
            [_vm._v("\n      Telefone de Contato\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "6" }
            },
            [
              _vm._v(
                "\n      (" +
                  _vm._s(_vm.cliente.telefones.contato.ddd) +
                  ") " +
                  _vm._s(_vm.cliente.telefones.contato.telefone) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "6" }
            },
            [_vm._v("\n      Telefone Celular\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "6" }
            },
            [
              _vm._v(
                "\n      (" +
                  _vm._s(_vm.cliente.telefones.celular.ddd) +
                  ") " +
                  _vm._s(_vm.cliente.telefones.celular.telefone) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Renda Formal")])
          ])
        ],
        1
      ),
      _vm._l(_vm.cliente.rendasFormais, function(renda, index) {
        return _c(
          "div",
          { key: renda.uid },
          [
            _vm.isCaracteristicaNotSemRendaResult(renda)
              ? _c(
                  "v-row",
                  {
                    staticClass:
                      "form-row-result resumo-list form-row-result-first",
                    attrs: { "no-gutters": "" }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        class: [..._vm.style.colLeftClasses],
                        attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                      },
                      [
                        _c("span", { staticClass: "text-capitalize" }, [
                          _vm._v(
                            _vm._s(
                              renda.tipoFonte
                                ? renda.tipoFonte.toLowerCase()
                                : ""
                            )
                          )
                        ])
                      ]
                    ),
                    _c(
                      "v-col",
                      {
                        class: _vm.style.colRightClasses,
                        attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(renda.cpfCnpj) + "\n      "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.isCaracteristicaNotSemRendaResult(renda)
              ? _c(
                  "v-row",
                  {
                    staticClass: "form-row-result resumo-list",
                    attrs: { "no-gutters": "" }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        class: [..._vm.style.colLeftClasses],
                        attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                      },
                      [_vm._v("\n        Ocupação\n      ")]
                    ),
                    _c(
                      "v-col",
                      {
                        class: _vm.style.colRightClasses,
                        attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(renda.ocupacao) + "\n      "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.isCaracteristicaNotSemRendaResult(renda)
              ? _c(
                  "v-row",
                  {
                    staticClass: "form-row-result resumo-list",
                    attrs: { "no-gutters": "" }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        class: [..._vm.style.colLeftClasses],
                        attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                      },
                      [_vm._v("\n        Admissão\n      ")]
                    ),
                    _c(
                      "v-col",
                      {
                        class: _vm.style.colRightClasses,
                        attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(renda.dataAdmissao) + "\n      "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-row",
              {
                class: [
                  "form-row-result",
                  "resumo-list",
                  _vm.isCaracteristicaNotSemRendaResult(renda)
                    ? ""
                    : "form-row-result-first"
                ],
                attrs: { "no-gutters": "" }
              },
              [
                _c(
                  "v-col",
                  {
                    class: [..._vm.style.colLeftClasses],
                    attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                  },
                  [_vm._v("\n        Característica da Renda\n      ")]
                ),
                _c(
                  "v-col",
                  {
                    class: _vm.style.colRightClasses,
                    attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(renda.caracteristica) + "\n      "
                    )
                  ]
                )
              ],
              1
            ),
            _vm.isCaracteristicaNotSemRendaResult(renda)
              ? _c(
                  "v-row",
                  {
                    staticClass: "form-row-result resumo-list",
                    attrs: { "no-gutters": "" }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        class: [..._vm.style.colLeftClasses],
                        attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                      },
                      [_vm._v("\n        Bruta\n      ")]
                    ),
                    _c(
                      "v-col",
                      {
                        class: [..._vm.style.colRightClasses, "form-col-left"],
                        attrs: { "align-self": _vm.style.alignSelf, cols: "3" }
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(renda.rendaBruta) + "\n      "
                        )
                      ]
                    ),
                    _c(
                      "v-col",
                      {
                        class: [..._vm.style.colLeftClasses],
                        attrs: { "align-self": _vm.style.alignSelf, cols: "2" }
                      },
                      [_vm._v("\n        Líquida\n      ")]
                    ),
                    _c(
                      "v-col",
                      {
                        class: _vm.style.colRightClasses,
                        attrs: { "align-self": _vm.style.alignSelf, cols: "3" }
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(renda.rendaLiquida) + "\n      "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-row",
              {
                staticClass: "form-row-result resumo-list",
                attrs: { "no-gutters": "" }
              },
              [
                _c(
                  "v-col",
                  {
                    class: [..._vm.style.colLeftClasses],
                    attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                  },
                  [_vm._v("\n        Comprovante de Renda\n      ")]
                ),
                _c(
                  "v-col",
                  {
                    class: _vm.style.colRightClasses,
                    attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(renda.documentoComprovante) +
                        "\n      "
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "v-row",
              {
                staticClass: "form-row-result resumo-list",
                attrs: { "no-gutters": "" }
              },
              [
                _c(
                  "v-col",
                  {
                    class: [..._vm.style.colLeftClasses],
                    attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                  },
                  [_vm._v("\n        Data da Comprovação de Renda\n      ")]
                ),
                _c(
                  "v-col",
                  {
                    class: _vm.style.colRightClasses,
                    attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(renda.dataComprovante) + "\n      "
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "v-row",
              {
                class: [
                  "form-row-result",
                  "resumo-list",
                  _vm.listItemMargin(index, _vm.cliente.rendasFormais)
                ],
                attrs: { "no-gutters": "" }
              },
              [
                _c(
                  "v-col",
                  {
                    class: [..._vm.style.colLeftClasses],
                    attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                  },
                  [_vm._v("\n        Valor do Imposto de Renda\n      ")]
                ),
                _c(
                  "v-col",
                  {
                    class: _vm.style.colRightClasses,
                    attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(renda.impostoRetido) + "\n      "
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      }),
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Rendas não Comprovadas / Informais")])
          ])
        ],
        1
      ),
      _vm._l(_vm.cliente.rendasInformais, function(renda, index) {
        return _c(
          "div",
          { key: renda.uid },
          [
            _c(
              "v-row",
              {
                staticClass:
                  "form-row-result resumo-list form-row-result-first",
                attrs: { "no-gutters": "" }
              },
              [
                _c(
                  "v-col",
                  {
                    class: [..._vm.style.colLeftClasses],
                    attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                  },
                  [_vm._v("\n        Atividade\n      ")]
                ),
                _c(
                  "v-col",
                  {
                    class: _vm.style.colRightClasses,
                    attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                  },
                  [_vm._v("\n        " + _vm._s(renda.atividade) + "\n      ")]
                )
              ],
              1
            ),
            _c(
              "v-row",
              {
                staticClass: "form-row-result resumo-list",
                attrs: { "no-gutters": "" }
              },
              [
                _c(
                  "v-col",
                  {
                    class: [..._vm.style.colLeftClasses],
                    attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                  },
                  [_vm._v("\n        Data Início\n      ")]
                ),
                _c(
                  "v-col",
                  {
                    class: _vm.style.colRightClasses,
                    attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                  },
                  [_vm._v("\n        " + _vm._s(renda.dataInicio) + "\n      ")]
                )
              ],
              1
            ),
            _c(
              "v-row",
              {
                staticClass: "form-row-result resumo-list",
                attrs: { "no-gutters": "" }
              },
              [
                _c(
                  "v-col",
                  {
                    class: [..._vm.style.colLeftClasses],
                    attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                  },
                  [_vm._v("\n        Renda Liquida\n      ")]
                ),
                _c(
                  "v-col",
                  {
                    class: _vm.style.colRightClasses,
                    attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(renda.rendaLiquidaMensal) +
                        "\n      "
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "v-row",
              {
                class: [
                  "form-row-result",
                  "resumo-list",
                  _vm.listItemMargin(index, _vm.cliente.rendasInformais)
                ],
                attrs: { "no-gutters": "" }
              },
              [
                _c(
                  "v-col",
                  {
                    class: [..._vm.style.colLeftClasses],
                    attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                  },
                  [_vm._v("\n        Comprovante de Renda Informal\n      ")]
                ),
                _c(
                  "v-col",
                  {
                    class: _vm.style.colRightClasses,
                    attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column" },
                      _vm._l(
                        _vm.comprovanteDeRendaInformal(
                          renda.documentoComprovacao
                        ),
                        function(comp, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "d-flex flex-row align-center"
                            },
                            [
                              _vm._v(
                                "\n            " + _vm._s(comp) + "\n          "
                              )
                            ]
                          )
                        }
                      ),
                      0
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      }),
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Patrimônio Consolidado")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "form-row-result resumo-list",
          attrs: { "no-gutters": "" }
        },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "6" }
            },
            [_vm._v("\n      Valor do Patrimônio Consolidado do Cliente\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: { "align-self": _vm.style.alignSelf, cols: "6" }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.cliente.patrimonioConsolidado.valorPatrimonio) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [
              _vm._v("Agência de Relacionamento (Definida pelo Cliente)")
            ])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "form-row-result resumo-list",
          attrs: { "no-gutters": "" }
        },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "6" }
            },
            [_vm._v("\n      UF\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: { "align-self": _vm.style.alignSelf, cols: "6" }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.cliente.agenciaRelacionamento.uf) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "form-row-result resumo-list",
          attrs: { "no-gutters": "" }
        },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "6" }
            },
            [_vm._v("\n      Município\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: { "align-self": _vm.style.alignSelf, cols: "6" }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.cliente.agenciaRelacionamento.municipio) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "form-row-result resumo-list",
          attrs: { "no-gutters": "" }
        },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: { "align-self": _vm.style.alignSelf, cols: "6" }
            },
            [_vm._v("\n      Código e Nome das Agências\n    ")]
          ),
          _c(
            "v-col",
            {
              class: [..._vm.style.colRightClasses, "text-uppercase"],
              attrs: { "align-self": _vm.style.alignSelf, cols: "6" }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.cliente.agenciaRelacionamento.codigoNomeAgencia) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass:
                "form-row-result-no-border text-justify texto-autorizacao"
            },
            [
              _c("div", [
                _vm._v(
                  "\n        Autorizo, nos termos da Resolução CMN n 3.658, de 17.12.2008, a CAIXA a consultar as informações consolidadas\n        relativas a minha pessoa, constantes no Sistema de Informações de Créditos (SCR) do BACEN. Autorizo, ainda, a\n        CAIXA a fornecer informações sobre as operações de crédito com ela realizadas, no sentido de compor o cadastro\n        do já citado Sistema. Os presentes dados são verdadeiros e visam facilitar os processos de negociação e\n        transações comerciais, pela antecipação de informações a meu respeito. Autorizo o arquivamento dos meus dados\n        pessoais e de idoneidade na SERASA - Centralização de Serviços dos Bancos S/A, que poderá deles se utilizar,\n        respeitadas as disposições legais em vigor.\n      "
                )
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-12", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "text-center assinatura-border mx-1",
              attrs: { "align-self": _vm.style.alignSelf }
            },
            [_vm._v("\n      LOCAL\n    ")]
          ),
          _c(
            "v-col",
            {
              staticClass: "text-center assinatura-border mx-1",
              attrs: { "align-self": _vm.style.alignSelf }
            },
            [_vm._v("\n      DATA\n    ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-12", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "text-center assinatura-border mx-1",
              attrs: { "align-self": _vm.style.alignSelf }
            },
            [_vm._v("\n      Assinatura do Cliente\n    ")]
          ),
          _c(
            "v-col",
            {
              staticClass: "text-center assinatura-border mx-1",
              attrs: { "align-self": _vm.style.alignSelf }
            },
            [_vm._v("\n      Assinatura do Correspondente\n    ")]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-12", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "form-row-result-no-border text-center" },
            [
              _c("div", [
                _c("b", [_vm._v("SAC CAIXA")]),
                _vm._v(
                  ": 0800 726 0101 (informações, reclamações, sugestões e elogios)"
                )
              ]),
              _c("div", [
                _c("b", [
                  _vm._v("Para pessoas com deficiência auditica ou de fala")
                ]),
                _vm._v(": 0800 726 2492")
              ]),
              _c("div", [
                _c("b", [_vm._v("Ouvidoria")]),
                _vm._v(": 0800 725 7474")
              ]),
              _c("div", [_c("b", [_vm._v("caixa.gov.br")])])
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-8", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "text-right" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-azul",
                attrs: { id: "btn-imprimir" },
                on: { click: _vm.print }
              },
              [_vm._v("Imprimir Formulário")]
            )
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-6", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "text-right" }, [
            _vm.showAvaliacaoRiscoLink
              ? _c(
                  "a",
                  {
                    staticClass: "btn btn-azul",
                    attrs: { id: "btn-avalicacao-risco" },
                    on: { click: _vm.toAvaliacaoRisco }
                  },
                  [_vm._v("Avaliação de Risco")]
                )
              : _vm._e()
          ])
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }