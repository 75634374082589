<!--suppress HtmlFormInputWithoutLabel -->
<template>
  <ClienteResumo :cliente="cliente" :show-avaliacao-risco-link="showAvaliacaoRiscoLink"
    :to-avaliacao-risco="toAvaliacaoRisco"></ClienteResumo>
</template>

<script>
// noinspection ES6CheckImport
import { mapCliente } from "../../../store/modules/caixaqui-clientes";
import ROUTERS from "../../../routes/router-constants";
import { mapGetters } from "vuex";
import { operationTypes } from "../../../store/modules/controller";
import ClienteResumo from "../../../components/caixaqui/ClienteResumo";

export default {
  name: "CaixaquiCadastroClientesResumo",
  components: {
    ClienteResumo
  },
  data: () => ({}),
  computed: {
    ...mapCliente(["cliente"]),
    ...mapGetters("controller", ["operationType"]),
    isDocumentoIdentificacaoVisible() {
      return this.tipoDocumento === "DOCUMENTO DE IDENTIFICAÇÃO";
    },
    isPassaporteVisible() {
      return this.cliente.tipoDocumento.value === "PASSAPORTE";
    },
    isCnhVisible() {
      return this.cliente.tipoDocumento.value === "CNH - CARTEIRA NACIONAL DE HABILITAÇÃO";
    },
    isCaracteristicaNotSemRendaResult() {
      return renda => renda.caracteristica !== "SEM RENDA";
    },
    comprovanteDeRendaInformal() {
      return documentoComprovacao => {
        const result = [];
        if (documentoComprovacao && documentoComprovacao.value === "SIM") {
          Object.keys(documentoComprovacao)
            .filter(key => key !== "value")
            .forEach(key => {
              if (documentoComprovacao[key].selected) {
                result.push(documentoComprovacao[key].text);
              }
            });
        } else {
          result.push("NÃO");
        }
        return result;
      };
    },
    showAvaliacaoRiscoLink() {
      return this.operationType !== operationTypes.CADASTRO_DOSSIE;
    }
  },
  methods: {
    handleForwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE AVANCAR PARA A PROXIMA PAGINA
      if (to) {
        this.$router.push(to);
      }
    },
    handleBackwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE VOLTAR PARA A PAGINA
      if (to) {
        this.$router.push(to);
      }
    },
    toAvaliacaoRisco() {
      this.$router.push({ name: ROUTERS.caixaqui.avaliacaoRiscos.dadosTomador.name });
    }
  }
};
</script>

<style scoped>

</style>
