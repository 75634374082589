<!--suppress HtmlFormInputWithoutLabel -->
<template>
  <v-container class="form-main" id="resumo">
    <!-- DADOS DE IDENTIFICAÇÃO-->
    <v-row no-gutters>
      <v-col class="form-header text-center">
        <div>Dados de Identificação</div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Protocolo
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        {{ cliente.protocolo }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Código do Correspondente
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        {{ cliente.codCorrespondente }}
      </v-col>
    </v-row>

    <!-- DECLARAÇÃO DE PROPÓSITOS -->
    <v-row class="mt-3" no-gutters>
      <v-col class="form-header text-center">
        <div>Declaração de Propósitos</div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses">
        <div class="d-flex flex-column">
          <div class="d-flex flex-row align-center">
            <input disabled id="movimentaContaDepositoPoupanca" name="movimentaContaDepositoPoupanca" type="checkbox"
              v-model="cliente.declaracaoPropositos.movimentacaoContaDepositoPoupanca" />
            <label class="form-checkbox-label" for="movimentaContaDepositoPoupanca">Movimentação de Conta de
              Depósito/Poupança</label>
          </div>

          <div class="d-flex flex-row align-center">
            <input disabled id="emprestimosFinanciamentos" name="emprestimosFinanciamentos" type="checkbox"
              v-model="cliente.declaracaoPropositos.emprestimosFinanciamentos" />
            <label class="form-checkbox-label" for="emprestimosFinanciamentos">Empréstimos/Financiamentos</label>
          </div>

          <div class="d-flex flex-row align-center justify-center" id="habitacional">
            <label class="form-checkbox-label">Financiamento Habitacional</label>
            <input disabled id="habitacionalSIM" name="habitacional" type="radio" v-bind:value="true"
              v-model="cliente.declaracaoPropositos.financiamentoHabitacional" />
            <label class="form-checkbox-label" for="habitacionalSIM">SIM</label>
            <input disabled id="habitacionalNAO" name="habitacional" type="radio" v-bind:value="false"
              v-model="cliente.declaracaoPropositos.financiamentoHabitacional" />
            <label class="form-checkbox-label" for="habitacionalNAO">NÃO</label>
          </div>

          <div class="d-flex flex-row align-center">
            <input disabled id="investimentos" name="investimentos" type="checkbox"
              v-model="cliente.declaracaoPropositos.investimentos" />
            <label class="form-checkbox-label" for="investimentos">Investimentos</label>
          </div>

          <div class="d-flex flex-row align-center">
            <input disabled id="cartaoCredito" name="cartaoCredito" type="checkbox"
              v-model="cliente.declaracaoPropositos.cartaoCredito" />
            <label class="form-checkbox-label" for="cartaoCredito">Cartão de Crédito</label>
          </div>

          <div class="d-flex flex-row align-center">
            <input disabled id="seguroPrevidenciaCapitalizacaoConsorcios"
              name="seguroPrevidenciaCapitalizacaoConsorcios" type="checkbox"
              v-model="cliente.declaracaoPropositos.seguroPrevidenciaCapitalizacaoConsorcios" />
            <label class="form-checkbox-label" for="seguroPrevidenciaCapitalizacaoConsorcios">Seguros/Previdência
              Privada/Capitalização/Consórcios</label>
          </div>

          <div class="d-flex flex-row align-center">
            <input disabled id="operacoesInternacionaisCambio" name="operacoesInternacionaisCambio" type="checkbox"
              v-model="cliente.declaracaoPropositos.operacoesInternacionaisCambio" />
            <label class="form-checkbox-label" for="operacoesInternacionaisCambio">Operações
              Internacionais/Câmbio</label>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- DADOS CADASTRAIS -->
    <v-row class="mt-3" no-gutters>
      <v-col class="form-header text-center">
        <div>Dados Cadastrais</div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        CPF do Cliente
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        {{ cliente.cpf }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Nome Completo do Cliente
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        {{ cliente.nomeCompleto }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Nome Reduzido do Cliente
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        {{ cliente.nomeReduzido }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Data de Nascimento
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'form-col-left', 'text-uppercase']"
        cols="3">
        {{ cliente.dataNascimento }}
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="2">
        Sexo
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" cols="3">
        {{ cliente.sexo }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Nacionalidade
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        {{ cliente.nacionalidade }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Naturalidade
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        {{ cliente.naturalidade.municipio }} / {{ cliente.naturalidade.uf }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Nome do Pai
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        {{ cliente.nomePai }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Nome do Mãe
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        {{ cliente.nomeMae }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Grau de Instrução
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        {{ cliente.grauInstrucao }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        PIS/NIS
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        {{ cliente.pisNis }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Tipo de Documento de Identidade
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        {{ cliente.tipoDocumento.value }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters v-if="isDocumentoIdentificacaoVisible">
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Subtipo do Documento
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        {{ cliente.tipoDocumento.documentoIdentificacao.subTipo }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters v-if="cliente.tipoDocumento.value">
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        <span v-if="isPassaporteVisible">Número do Passaporte</span>
        <span v-if="isCnhVisible">Número da Carteira Nacional de Habilitação</span>
        <span v-else>Número da Identidade</span>
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        {{ cliente.tipoDocumento.numero }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters v-if="cliente.tipoDocumento.value">
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Órgão Emissor
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        {{ cliente.tipoDocumento.orgaoEmissor }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters v-if="cliente.tipoDocumento.value">
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        UF
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        {{ cliente.tipoDocumento.uf }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters v-if="isPassaporteVisible">
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        País de origem
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        {{ cliente.tipoDocumento.passaporte.paisOrigem }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters v-if="isCnhVisible">
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Data 1ª Habilitação
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        {{ cliente.tipoDocumento.dataPrimeiraHabilitacao }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters v-if="cliente.tipoDocumento.value">
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Data de Emissão
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'form-col-left']" cols="2">
        {{ cliente.tipoDocumento.dataEmissao }}
      </v-col>

      <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'text-no-wrap', 'form-fim-val-label']"
        cols="3">
        Data Fim Validade
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="3">
        {{ cliente.tipoDocumento.dataFimValidade }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Estado Civil
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        {{ cliente.estadoCivil }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Tipo de Ocupação
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        {{ cliente.ocupacao }}
      </v-col>
    </v-row>

    <!-- ENDEREÇO -->
    <v-row class="mt-3" no-gutters>
      <v-col class="form-header text-center">
        <div>Endereço</div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        CEP
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        {{ cliente.endereco.cep }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Logradouro
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        {{ cliente.endereco.tipoLogradouro }} {{ cliente.endereco.logradouro }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Complemento
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'form-col-left', 'text-uppercase']"
        cols="5">
        {{ cliente.endereco.complemento }}
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="2">
        Número
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" cols="1">
        {{ cliente.endereco.numero }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Município
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'form-col-left', 'text-uppercase']"
        cols="5">
        {{ cliente.endereco.cidade }}
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="2">
        Número
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" cols="1">
        {{ cliente.endereco.uf }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Bairro
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        {{ cliente.endereco.bairro }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Tipo de Imóvel
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        {{ cliente.endereco.tipoImovel }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Ocupação do Imóvel
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        {{ cliente.endereco.ocupacaoImovel }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Comprovante de Residência
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" :cols="style.colRight">
        {{ cliente.endereco.comprovanteResidenciaMes }}/{{ cliente.endereco.comprovanteResidenciaAno }}
      </v-col>
    </v-row>

    <!-- TELEFONES -->
    <v-row class="mt-3" no-gutters>
      <v-col class="form-header text-center">
        <div>Telefones</div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="6">
        Telefone de Contato
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="6">
        ({{ cliente.telefones.contato.ddd }}) {{ cliente.telefones.contato.telefone }}
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="6">
        Telefone Celular
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="6">
        ({{ cliente.telefones.celular.ddd }}) {{ cliente.telefones.celular.telefone }}
      </v-col>
    </v-row>

    <!-- RENDA FORMAL -->
    <v-row class="mt-3" no-gutters>
      <v-col class="form-header text-center">
        <div>Renda Formal</div>
      </v-col>
    </v-row>
    <div :key="renda.uid" v-for="(renda, index) in cliente.rendasFormais">
      <v-row class="form-row-result resumo-list form-row-result-first" no-gutters
        v-if="isCaracteristicaNotSemRendaResult(renda)">
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="4">
          <span class="text-capitalize">{{ renda.tipoFonte ? renda.tipoFonte.toLowerCase() : "" }}</span>
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ renda.cpfCnpj }}
        </v-col>
      </v-row>

      <v-row class="form-row-result resumo-list" no-gutters v-if="isCaracteristicaNotSemRendaResult(renda)">
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="4">
          Ocupação
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ renda.ocupacao }}
        </v-col>
      </v-row>

      <v-row class="form-row-result resumo-list" no-gutters v-if="isCaracteristicaNotSemRendaResult(renda)">
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="4">
          Admissão
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ renda.dataAdmissao }}
        </v-col>
      </v-row>

      <v-row :class="[
        'form-row-result',
        'resumo-list',
        isCaracteristicaNotSemRendaResult(renda) ? '' : 'form-row-result-first'
      ]" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="4">
          Característica da Renda
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ renda.caracteristica }}
        </v-col>
      </v-row>

      <v-row class="form-row-result resumo-list" no-gutters v-if="isCaracteristicaNotSemRendaResult(renda)">
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="4">
          Bruta
        </v-col>
        <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'form-col-left']" cols="3">
          {{ renda.rendaBruta }}
        </v-col>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="2">
          Líquida
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="3">
          {{ renda.rendaLiquida }}
        </v-col>
      </v-row>

      <v-row class="form-row-result resumo-list" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="4">
          Comprovante de Renda
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ renda.documentoComprovante }}
        </v-col>
      </v-row>

      <v-row class="form-row-result resumo-list" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="4">
          Data da Comprovação de Renda
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ renda.dataComprovante }}
        </v-col>
      </v-row>

      <v-row :class="['form-row-result', 'resumo-list', listItemMargin(index, cliente.rendasFormais)]" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="4">
          Valor do Imposto de Renda
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ renda.impostoRetido }}
        </v-col>
      </v-row>
    </div>

    <!-- RENDAS NÃO COMPROVADAS / INFORMAIS -->
    <v-row class="mt-3" no-gutters>
      <v-col class="form-header text-center">
        <div>Rendas não Comprovadas / Informais</div>
      </v-col>
    </v-row>
    <div :key="renda.uid" v-for="(renda, index) in cliente.rendasInformais">
      <v-row class="form-row-result resumo-list form-row-result-first" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="4">
          Atividade
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ renda.atividade }}
        </v-col>
      </v-row>
      <v-row class="form-row-result resumo-list" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="4">
          Data Início
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ renda.dataInicio }}
        </v-col>
      </v-row>
      <v-row class="form-row-result resumo-list" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="4">
          Renda Liquida
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ renda.rendaLiquidaMensal }}
        </v-col>
      </v-row>
      <v-row :class="['form-row-result', 'resumo-list', listItemMargin(index, cliente.rendasInformais)]" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses]" cols="4">
          Comprovante de Renda Informal
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          <div class="d-flex flex-column">
            <div :key="index" class="d-flex flex-row align-center"
              v-for="(comp, index) in comprovanteDeRendaInformal(renda.documentoComprovacao)">
              {{ comp }}
            </div>
          </div>
        </v-col>
      </v-row>
    </div>

    <!-- PATRIMONIO CONSOLIDADO -->
    <v-row class="mt-3" no-gutters>
      <v-col class="form-header text-center">
        <div>Patrimônio Consolidado</div>
      </v-col>
    </v-row>
    <v-row class="form-row-result resumo-list" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="6">
        Valor do Patrimônio Consolidado do Cliente
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" cols="6">
        {{ cliente.patrimonioConsolidado.valorPatrimonio }}
      </v-col>
    </v-row>

    <!-- AGÊNCIA DE RELACIONAMENTO -->
    <v-row class="mt-3" no-gutters>
      <v-col class="form-header text-center">
        <div>Agência de Relacionamento (Definida pelo Cliente)</div>
      </v-col>
    </v-row>
    <v-row class="form-row-result resumo-list" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="6">
        UF
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" cols="6">
        {{ cliente.agenciaRelacionamento.uf }}
      </v-col>
    </v-row>
    <v-row class="form-row-result resumo-list" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="6">
        Município
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" cols="6">
        {{ cliente.agenciaRelacionamento.municipio }}
      </v-col>
    </v-row>
    <v-row class="form-row-result resumo-list" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="6">
        Código e Nome das Agências
      </v-col>
      <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'text-uppercase']" cols="6">
        {{ cliente.agenciaRelacionamento.codigoNomeAgencia }}
      </v-col>
    </v-row>

    <!-- AUTORIZACAO -->
    <v-row class="mt-3" no-gutters>
      <v-col class="form-row-result-no-border text-justify texto-autorizacao">
        <div>
          Autorizo, nos termos da Resolução CMN n 3.658, de 17.12.2008, a CAIXA a consultar as informações consolidadas
          relativas a minha pessoa, constantes no Sistema de Informações de Créditos (SCR) do BACEN. Autorizo, ainda, a
          CAIXA a fornecer informações sobre as operações de crédito com ela realizadas, no sentido de compor o cadastro
          do já citado Sistema. Os presentes dados são verdadeiros e visam facilitar os processos de negociação e
          transações comerciais, pela antecipação de informações a meu respeito. Autorizo o arquivamento dos meus dados
          pessoais e de idoneidade na SERASA - Centralização de Serviços dos Bancos S/A, que poderá deles se utilizar,
          respeitadas as disposições legais em vigor.
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-12" no-gutters>
      <v-col :align-self="style.alignSelf" class="text-center assinatura-border mx-1">
        LOCAL
      </v-col>
      <v-col :align-self="style.alignSelf" class="text-center assinatura-border mx-1">
        DATA
      </v-col>
    </v-row>

    <v-row class="mt-12" no-gutters>
      <v-col :align-self="style.alignSelf" class="text-center assinatura-border mx-1">
        Assinatura do Cliente
      </v-col>
      <v-col :align-self="style.alignSelf" class="text-center assinatura-border mx-1">
        Assinatura do Correspondente
      </v-col>
    </v-row>

    <v-row class="mt-12" no-gutters>
      <v-col class="form-row-result-no-border text-center">
        <div><b>SAC CAIXA</b>: 0800 726 0101 (informações, reclamações, sugestões e elogios)</div>
        <div><b>Para pessoas com deficiência auditica ou de fala</b>: 0800 726 2492</div>
        <div><b>Ouvidoria</b>: 0800 725 7474</div>
        <div>
          <b>caixa.gov.br</b>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-8" no-gutters>
      <v-col class="text-right">
        <a @click="print" class="btn btn-azul" id="btn-imprimir">Imprimir Formulário</a>
      </v-col>
    </v-row>
    <v-row class="mt-6" no-gutters>
      <v-col class="text-right">
        <a @click="toAvaliacaoRisco" class="btn btn-azul" id="btn-avalicacao-risco"
          v-if="showAvaliacaoRiscoLink">Avaliação de Risco</a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// noinspection ES6CheckImport

export default {
  name: "ClienteResumo",
  props: ["cliente", "toAvaliacaoRisco", "showAvaliacaoRiscoLink"],

  data: () => ({
    style: {
      colLeft: 4,
      colRight: 8,
      alignSelf: "stretch",
      colLeftClasses: ["form-col-left", "d-flex", "flex-row", "align-center"],
      colRightClasses: ["form-col-right", "d-flex", "flex-row", "align-center"]
    }
  }),
  computed: {
    isDocumentoIdentificacaoVisible() {
      return this.tipoDocumento === "DOCUMENTO DE IDENTIFICAÇÃO";
    },
    isPassaporteVisible() {
      return this.cliente.tipoDocumento.value === "PASSAPORTE";
    },
    isCnhVisible() {
      return this.cliente.tipoDocumento.value === "CNH - CARTEIRA NACIONAL DE HABILITAÇÃO";
    },
    isCaracteristicaNotSemRendaResult() {
      return renda => renda.caracteristica !== "SEM RENDA";
    },
    comprovanteDeRendaInformal() {
      return documentoComprovacao => {
        const result = [];
        if (documentoComprovacao && documentoComprovacao.value === "SIM") {
          Object.keys(documentoComprovacao)
            .filter(key => key !== "value")
            .forEach(key => {
              if (documentoComprovacao[key].selected) {
                result.push(documentoComprovacao[key].text);
              }
            });
        } else {
          result.push("NÃO");
        }
        return result;
      };
    }
  },
  methods: {
    listItemMargin(curIndex, list) {
      return curIndex >= list.length ? "" : "mb-2";
    },
    print() {
      window.print();
    }
  }
};
</script>

<style scoped src="./caixaqui-style.css">

</style>
<style scoped>
.texto-autorizacao {
  font-weight: bold;
  font-style: italic;
}

.assinatura-border {
  border-top: 1px solid #708090;
}

.resumo-list {
  background-color: #f0f0f0;
}

#btn-avalicacao-risco,
#btn-imprimir {
  padding: 3px 12px 3px 12px;
}
</style>
