var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ClienteResumo", {
    attrs: {
      cliente: _vm.cliente,
      "show-avaliacao-risco-link": _vm.showAvaliacaoRiscoLink,
      "to-avaliacao-risco": _vm.toAvaliacaoRisco
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }